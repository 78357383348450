// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-collaborators-page-js": () => import("./../../src/templates/collaborators-page.js" /* webpackChunkName: "component---src-templates-collaborators-page-js" */),
  "component---src-templates-fn-page-js": () => import("./../../src/templates/fn-page.js" /* webpackChunkName: "component---src-templates-fn-page-js" */),
  "component---src-templates-formal-page-js": () => import("./../../src/templates/formal-page.js" /* webpackChunkName: "component---src-templates-formal-page-js" */),
  "component---src-templates-hero-page-js": () => import("./../../src/templates/hero-page.js" /* webpackChunkName: "component---src-templates-hero-page-js" */),
  "component---src-templates-stiftelsen-page-js": () => import("./../../src/templates/stiftelsen-page.js" /* webpackChunkName: "component---src-templates-stiftelsen-page-js" */),
  "component---src-templates-styret-page-js": () => import("./../../src/templates/styret-page.js" /* webpackChunkName: "component---src-templates-styret-page-js" */),
  "component---src-templates-vedtekter-page-js": () => import("./../../src/templates/vedtekter-page.js" /* webpackChunkName: "component---src-templates-vedtekter-page-js" */)
}

